import React, { useContext } from 'react';
import styled from 'styled-components';
import { HubHeader, HubFooter, Terms, EYSponsor, SEO, EmailHandler } from 'components';
import { LocalContext } from 'context';
import { gridContainer, H5, defaultColors, breakpoints } from 'styles';
import { useEventData } from 'hooks';

const Home = () => {
  const { theme, scrollTo, registerRef } = useContext(LocalContext);
  const { edges: events } = useEventData();

  return (
    <>
      <SEO pageSpecificTitle="The Forum" />
      <EmailHandler />
      <HubHeader
        scrollToRegister={() => scrollTo(registerRef)}
        colors={defaultColors}
        events={events}
      />
      <EYSponsor />
      <Terms />
      <HubFooter />
    </>
  );
};

const SpeakersContainer = styled.section`
  ${gridContainer}
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  width: 100%;
  ${H5} {
    margin: 0 auto;
    text-align: center;
    color: ${({ $theme }) => $theme.textColor};
  }
  p {
    color: ${({ $theme }) => $theme.textColor};
  }
  ${breakpoints.sm} {
    padding: 3rem 1.25rem;
  }
  ${breakpoints.lg} {
    padding: 6rem 0;
    grid-column: 1/8;
  }
  ${breakpoints.xl} {
    grid-column: 3/11;
    padding: 6rem 0;
  }
`;

export default Home;
